import axios from 'axios';
import qs from 'qs';
import firestoreService from '../services/firestore';

const confirmCheckIn = async (data: any) => {
    try {
        await firestoreService.confirmCheckIn(data);
    } catch (err: any) {
        throw new Error(err.message);
    }
};

const searchQuery = async (searchText: string, userType: string, customerId: string): Promise<Customer[]> => {
    let employeeOrGuestCollection;

    if (userType === 'employee') {
        employeeOrGuestCollection = 'checkin_employees';
    } else if (userType === 'guest') {
        employeeOrGuestCollection = 'guests';
    } else {
        throw new Error('Invalid user type');
    }

    return await firestoreService.db()
        .collection('customers')
        .doc(customerId)
        .collection(employeeOrGuestCollection)
        .where('searchFullName', 'array-contains', searchText.toLowerCase())
        .where('isActive', '==', true)
        .get()
        .then((data: any) =>
            data.docs.map((doc: any) => {
                const d = {
                    id: doc.id
                };
                const theData = { ...d, ...doc.data() };
                return theData as Customer;
            })
        );
};

const validateCheckinState = async (customerId: string, email: string) => {
    try {
        return await firestoreService.db()
            .collection('customers')
            .doc(customerId)
            .collection('checkin_logs')
            .where('email', '==', email)
            .get()
            .then((data: any) => data.docs.map((doc: any) => doc.data()));
    } catch (err: any) {
        throw new Error(err.message);
    }
};

const notifyHost = async (data: any) => {
    try {
        const url = `${process.env.VUE_APP_FIREBASE_CF_URL}/notifyHost`;
        return await axios.post(url, qs.stringify(data), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
    } catch (err: any) {
        throw new Error(err.message);
    }
};

const addGuestRecord = async (customerId: string, payload: any) => {
    try {
        await firestoreService.db()
            .collection('customers')
            .doc(customerId)
            .collection('checkin_logs')
            .add(payload);
    } catch (err: any) {
        throw new Error(err.message);
    }
};

export {
    notifyHost,
    addGuestRecord,
    validateCheckinState,
    searchQuery,
    confirmCheckIn
};
